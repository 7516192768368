<template>
  <div id="goodwillAnalysis">
    <div class="pages hidden" id="goodwillAnalysisPages">
      <div class="page_title"><span class="text hidden" id="goodwillAnalysisText"></span></div>
      <div class="page_date" v-show="updateDate">数据截止：{{ updateDate }}</div>
      <div ref="refTrend" v-if="isTrend">
        <line-chart ref="refTrendChart" id="TrendChart" customClass="line_chart" />
      </div>
      <div ref="refRank" v-if="isRank">
        <table-chart ref="refRankTable" id="RankTable" customClass="quote_table_chart" />
      </div>
      <div ref="refTotal" v-if="isTotal">
        <bar-chart ref="refTotalBar" id="TotalBar" customClass="bar_chart" />
      </div>
    </div>
  </div>
</template>
<script>
//特别注意：使用jquery的时候为了避免重名带来的干扰，选择器开头必须是marketChart
import $ from "jquery";
import { anyElement } from "@/components/anyElement.js";
import { sleep, setRem } from "@/utils/utils.js";
import lineChart from "@/components/lineChart/index_v1.0.vue";
import tableChart from "@/components/tableChart/index.vue";
import barChart from "@/components/barChart/index_v1.0.vue";

export default {
  data: function () {
    return {
      titleText: null,  // 动画句柄
      updateDate: "",  // 数据更新日期
      isTrend: false,
      isRank: false,
      isTotal: false,
    };
  },
  components: {
    lineChart,
    tableChart,
    barChart
  },
  methods: {
    preview(commandData) {
      setRem(1080);
      this.show(commandData)
    },

    async hide() {
      return new Promise(async (resolve, reject) => {
        if (this.titleText) {
          this.titleText.runOut();
        }
        await sleep(200);

        $("#goodwillAnalysisText").addClass("hidden");
        this.updateDate = "";
        this.isTrend = false;
        this.isRank = false;
        this.isTotal = false;
        resolve();
      });
    },
    
    async show(commandData) {
      this.updateDate = "";
      this.isTrend = false;
      this.isRank = false;
      this.isTotal = false;
      //设置标题
      const title = commandData.data.pageTitle;
      $("#goodwillAnalysisPages").removeClass("hidden");
      $("#goodwillAnalysisText").html(title);
      this.$nextTick(async () => {
        $("#goodwillAnalysisText").removeClass("hidden");
        this.titleText = anyElement({
          targets: document.querySelector("#goodwillAnalysisText"),
          isSplitTxt: true,
          duration: 600,
          delay: 30,
          easing: "easeOutExpo",
          inEffect: "rightInBig",
          outEffect: "leftOutBig",
        });
        await sleep(300);
        this.titleText.runIn();
        if (commandData.type === 'A股市场商誉走势') {
          this.handleTrendChart(commandData)
        } else if (commandData.type === '商誉比例排名') {
          this.handleRankTable(commandData)
        } else {
          this.handleTotalChart(commandData)
        }
      });
    },
    handleTrendChart(commandData) {
      this.isTrend = true
      this.$nextTick(() => {
        this.renderTrendChart(commandData)
      })
    },
    renderTrendChart(commandData) {
      let width = this.$refs.refTrend.clientWidth;
      this.$refs.refTrendChart.LineChart(commandData.data.data, {
        label: (d) => d.name,
        width: width,
        height: width * 0.74,
        marginLeft: 50,
        marginRight: 50,
        marginTop: 80,
        marginBottom: 50,
        duration: 1000,
        delay: 80,
        ease: "easeCubic",
        dotCircleRadius: 0,
        isShowTxtTips: false,
        xTickNumber: 3,
      });
    },
    handleRankTable(commandData) {
      this.isRank = true
      this.$nextTick(() => {
        this.renderRankTable(commandData)
      })
    },
    renderRankTable(commandData) {
      let width = this.$refs.refRank.clientWidth;
      this.$refs.refRankTable.TableChart(commandData.data.data, {
        width: width,
        height: width * 0.77,
        duration: 500,
        delay: 100,
        ease: "easeOutCubic",
      });
    },
    handleTotalChart(commandData) {
      this.isTotal = true
      this.$nextTick(() => {
        this.renderTotalChart(commandData)
      })
    },
    renderTotalChart(commandData) {
      this.updateDate = commandData.data.data.date
      let width = this.$refs.refTotal.clientWidth
      this.$refs.refTotalBar.BarChart(commandData.data.data.data,{
          x: (d) => d.name,
          y: (d) => d.value,
          width: width,
          height: width * 0.74,
          marginLeft: 60,
          marginRight: 25,
          marginTop: 50,
          marginBottom: 40,
          xPadding: 0.4,
          duration: 800,
          delay: 80,
          ease: "easeCubic",
          isFromZero: true,
          isShowNumber: true,
          xTickNumber:10,
          numberSuffix: '家'
      });
    }
  },

  async mounted() {
    window.hide = this.hide
    window.show = this.preview;
    this.$bus.$on("chartExit", () => {
      this.hide();
    });
  },
};
</script>
<style lang="less" scoped>
@import "./index.less";
</style>
